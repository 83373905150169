import React, { useEffect, useMemo, useState, } from 'react';
import dashboardService from '../../../services/dashboard/dashboard.service';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Card, Col, Row } from 'antd';
import DashboardType from './dashboard_type';
import { LoadingOutlined } from '@ant-design/icons';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import globalHelper from '../../../helpers/functions/global.helper';

const HomeDashboard = () => {
    const [state, setState] = useState({
        loading : false,
        dashboards : [],
    })

    const fetchDashboard = async () => {
        setState(state => ({...state, loading : true, }));

        const dashboardResponse = await dashboardService?.getDashboard();
        const { dashboards } = dashboardResponse;

        setState(state => ({...state, loading : false, dashboards : dashboards, }));
    }

    useEffect(() => {
        fetchDashboard();
    }, [])

    return (
        <>
            {
                state?.loading ?
                (
                    <>
                        <LoadingOutlined />
                    </>
                )
                :
                (
                    <>
                        {/* <ResponsiveMasonry
                        columnsCountBreakPoints={{350: 1, 750: 2, 900: 2}}
                        >
                            <Masonry gutter={20}>
                                {
                                    state?.dashboards?.map((dashboard, index) => {
                                        return (
                                            <div key={index}>
                                                <EntranceMotion delay={(index * 0.1)}>
                                                    <DashboardType dashboard={dashboard} />
                                                </EntranceMotion>
                                            </div>
                                        )
                                    })
                                }
                            </Masonry>
                        </ResponsiveMasonry> */}

                        <Row gutter={[12, 12]}>
                            {
                                state?.dashboards?.map((dashboard, index) => {
                                    const { size : span, } = dashboard;
                                    return (
                                        <Col key={index} xs={24} lg={span}>
                                            <EntranceMotion delay={(index * 0.1)}>
                                                <DashboardType
                                                dashboard={dashboard}
                                                />
                                            </EntranceMotion>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </>
                )
            }
        </>
    );
}

export default HomeDashboard;