import { Card, Input, List, Radio, Skeleton, Space, Spin } from 'antd';
import React, { useState, useRef, useEffect } from 'react';
import IconComponent from '../../../helpers/components/icon.component';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import { Line } from 'react-chartjs-2';
import dashboardTicketService from '../../../services/dashboard/dashboard_ticket.service';
import moment from 'moment';
import { CaretDownFilled, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import FilterBullet from '../../../helpers/components/filter_bullet';
import parkService from '../../../services/config/park.service';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TicketGrowth = ({ dashboard, }) => {
    const dashboardRedux = useSelector(state => state?.dashboard);
    const history = useHistory();
    const canvasRef = useRef();
    const [state, setState] = useState({
        loading : false,
        data : null,

        parks : [],

        // for redirect purpose
        labels : [],
    })
    const [filter, setFilter] = useState({
        park : null,
    })

    var options = {
        responsive: true,
        datasetStrokeWidth : 3,
        pointDotStrokeWidth : 4,
        maintainAspectRatio : false,
        scaleLabel : "1",
        plugins: {
            legend: {
                display: false
            },
            datalabels: {
                display : false,
            },
        },
        scales : {
            x: {
                grid : {
                    drawBorder: false,
                    display : false,
                },
                ticks: {
                    display: true,
                },
            },
            y: {
                beginAtZero : true,
                grid : {
                    drawBorder: false,
                    display: false,
                },
                ticks: {
                    display: false
                }
            }
        },
        onClick : (event, elements) => {
            // Check if any data points were clicked
            if (elements.length > 0) {
                handleDataPointClick(elements);
            }
        }
        
    };
    
    const height = 250;
    const width = "100%";

    // Create a function to handle the data point click event
    const handleDataPointClick = (element) => {
        if (element[0]) {
            const el = element[0];
            // This event array contains information about the clicked data point
            const datasetIndex = el?.datasetIndex;
            const index = el?.index;
            const date = state?.labels[index];
            history.push(`/ticket?start_date=${date}&end_date=${date}&parks=${filter?.park ?? ''}`);
        }
    };

    const fetchTicketGrowth = async () => {
        const parkResponse = await parkService.getParkByAuth();
        const ticketGrowthResponse = await dashboardTicketService?.getTicketGrowth({
            park_id : filter?.park,
        });

        setState(state => ({...state, parks : parkResponse?.parks}));

        return ticketGrowthResponse;
    }
    
    const initializeGrowthChart = async (response) => {
        const { date_breakdown_ticket_user_count } = response ?? {};
        // const gradient = canvasRef.current.ctx.createLinearGradient(0, 0, 0, height);
        // gradient.addColorStop(0, 'red');   
        // gradient.addColorStop(1, 'rgba(239, 219, 255, 0)');
        const data = {
            labels : date_breakdown_ticket_user_count?.map(x => moment(x.date)?.format('DD MMM')),
            datasets : [{
                label : 'Tickets',
                lineTension : 0.4,
                // backgroundColor : gradient, // Put the gradient here as a fill color
                borderColor : '#0555E1',
                borderWidth: 2,
                pointColor : "#fff",
                pointStrokeColor : '#0555E1',
                pointBackgroundColor : '#bae0ff',
                pointHighlightFill: "#fff",
                pointHighlightStroke: '#0555E1',
                data : date_breakdown_ticket_user_count?.map(x => x.ticket_user_count),
            }]
        }

        setState(state => ({...state, data : data, loading : false, labels : date_breakdown_ticket_user_count?.map(x => moment(x.date)?.format("YYYY-MM-DD")) }));
    }

    const onChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues}));
        // changeFilter(key, checkedValues);
    }

    useEffect(() => {
        setState(state => ({...state, loading : true,}));
        if(filter?.park){
            fetchTicketGrowth().then((response) => {
                initializeGrowthChart(response);
            });
        }
    }, [filter])

    useEffect(() => {
        setFilter(filter => ({...filter, park : dashboardRedux?.selected_park_id, }))
    }, [dashboardRedux])

    return (
        <>
            <Card
            title={(
                <>
                    <div style={{ textAlign : 'start', }}>
                        <span>{dashboard?.name}</span>
                    </div>
                </>
            )}
            >
                {/* <div>
                    <Space style={{width:'100%',}}>
                        <div style={{ display:'flex', height: 40, width: 40, border : '1px solid #e6e8eb', background:'#fff', borderRadius:'.5rem'}}>
                            <div style={{margin:'auto'}}>
                                <IconComponent name={dashboard?.menu?.icon} size={24} />
                            </div>
                        </div>
                        <div style={{textAlign:'start',}}>
                            <PageHeaderComponent title={dashboard?.name} />
                        </div>
                    </Space>
                </div>

                <div className='filter-panel' style={{marginTop : 12, textAlign:'start',}}>
                    <Space>
                        <FilterBullet
                        popover={
                            <>
                                <div>
                                    <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                </div>
                                
                                <Radio.Group
                                value={filter?.park}
                                // defaultValue={(state?.parks?.length > 0 && state?.parks[0]?.park_id)}
                                onChange={(e) => onChange('park', e.target.value)}
                                >
                                    <List
                                    style={{ width:280, }}
                                    dataSource={state?.parks}
                                    renderItem={(item, index) => {
                                        const { park_id, name, } = item ?? {};
                                        return (
                                            <List.Item style={{width:'100%'}} key={index}>
                                                <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                    <div>
                                                        <span>{name}</span>
                                                    </div>
                                                    <div style={{marginLeft:'auto'}}>
                                                        <Radio
                                                        value={park_id}
                                                        >

                                                        </Radio>
                                                    </div>
                                                </div>
                                            </List.Item>
                                        )
                                    }}
                                    />
                                </Radio.Group>
                            </>
                        }
                        >
                            <div>
                                <Space>
                                    <div>
                                        <span>Park{filter?.park && `: ${state?.parks?.find(x => x.park_id === filter?.park)?.name}` }</span>
                                    </div>
                                    <div>
                                        <CaretDownFilled style={{color:'#a0aec0'}} />
                                    </div>
                                </Space>
                            </div>
                        </FilterBullet>
                    </Space>
                </div> */}

                <div style={{marginTop:12,}}>
                    {
                        state?.loading ?
                        (
                            <>
                                <Loading />
                            </>
                        )
                        :
                        (
                            <>
                                <div style={{width : width, height : height, padding : '20px 8px'}}>
                                    <Line
                                    ref={canvasRef}
                                    options={options}
                                    data={( state?.data ?? {
                                        labels : [],
                                        datasets : [],
                                    })}
                                    // width={width}
                                    // height={height}
                                    />
                                </div>
                            </>
                        )
                    }
                </div>
            </Card>
        </>
    );
}

const Loading = () => {
    return (
        <>
            <Skeleton />
        </>
    );
}

export default TicketGrowth;