import React, { useEffect, useState } from 'react';
import dashboardTicketService from '../../../services/dashboard/dashboard_ticket.service';
import { Card, Col, Image, Row, Skeleton, Space, Statistic } from 'antd';
import CountUp from 'react-countup';
import IconComponent from '../../../helpers/components/icon.component';
import numberHelper from '../../../helpers/functions/number.helper';
import { useDispatch } from 'react-redux';
import { select_park_id } from '../../../actions/dashboard.action';

const ParkList = ({ dashboard, }) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        parks : [],
        total_ticket_count : null,
        total_ticket_user_count : null,

        selected_park_id : null,
    })

    const formatter = (value) => <CountUp end={value} separator="," />;

    const setParkId = (park_id) => {
        setState(state => ({...state, selected_park_id : park_id}));
        dispatch(select_park_id(park_id));
    }

    const fetchTicketDashboard = async () => {
        setState(state => ({...state, loading : true, }))
        const ticketCountResponse = await dashboardTicketService?.getTicketCountByPark();
        const { parks } = ticketCountResponse;
        const total_ticket_count = parks?.reduce((a, b) => (a + b?.ticket_count), 0);
        const total_ticket_user_count = parks?.reduce((a, b) => (a + b?.ticket_user_count), 0);
        setState(state => ({...state, loading : false, parks : parks, total_ticket_count : total_ticket_count, total_ticket_user_count : total_ticket_user_count, }))
    }

    useEffect(() => {
        fetchTicketDashboard();
    }, [])

    useEffect(() => {
        const park_id = state?.parks?.[0]?.park_id;
        setParkId(park_id)
    }, [state?.parks])

    return (
        <>
            <div style={{ paddingTop : 12, }}>
                <Row gutter={[12, 12]} justify={'center'}>
                    <Col span={12} xs={12} md={8}>
                        <Statistic title="Transactions" value={state?.total_ticket_count} formatter={formatter} />
                    </Col>

                    <Col span={12} xs={12} md={8}>
                        <Statistic title="Tickets" value={state?.total_ticket_user_count} formatter={formatter} />
                    </Col>
                </Row>
            </div>

            <div style={{ marginTop : 12, }}>
                {
                    state?.loading ?
                    (
                        <>
                            <ParkListLoading />
                        </>
                    )
                    :
                    (
                        <>
                            <Row gutter={[12, 12]}>
                            {
                                state?.parks?.map((park, index) => {
                                    const { park_id, ticket_count, ticket_user_count, image, name, } = park;
                                    const is_selected = state?.selected_park_id === park_id;
                                    return (
                                        <Col key={index} xs={12} md={6} sm={8} xl={4}>
                                            <Card
                                            onClick={() => setParkId(park_id)}
                                            className={`park-card ${is_selected ? 'selected' : ''}`}
                                            style={{ cursor : 'pointer', background : '' }}
                                            title={(
                                                <>
                                                    <div>
                                                        <div>
                                                            <Image preview={false} style={{ height : 22, maxWidth : 60, objectFit : 'contain', }} src={image} />
                                                        </div>
                                                        <div>
                                                            <span style={{ fontSize : 12, }}>{name}</span>
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            styles={{ body : { padding : 12, } }}
                                            >
                                                <div style={{ textAlign : 'start', }}>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span>{numberHelper?.commaNumber(ticket_count, 0)}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Transactions</span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                    <div>
                                                        <Space>
                                                            <div>
                                                                <span>{numberHelper?.commaNumber(ticket_user_count, 0)}</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize:12, color:'var(--secondary-text-color)'}}>Tickets</span>
                                                            </div>
                                                        </Space>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Col>
                                    )
                                })
                            }
                            </Row>
                        </>
                    )
                }
            </div>
        </>
    );
}

const ParkListLoading = () => {
    return (
        <>
            <Row gutter={[12, 12]} justify={'center'}>
                {
                    [...Array(6)]?.map((x, i) => {
                        return (
                            <Col key={i} xs={12} md={6} sm={8} xl={4}>
                                <Card>
                                    <Skeleton
                                    paragraph={2}
                                    title={null}
                                    />
                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
        </>
    );
}

export default ParkList;