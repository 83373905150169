import React, { useEffect, useState } from 'react';
import TicketList from './ticket_list';
import FilterBullet from '../../../helpers/components/filter_bullet';
import { CaretDownFilled, DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, DatePicker, Dropdown, Input, List, Modal, Space, Tooltip } from 'antd';
import parkService from '../../../services/config/park.service';
import { Link, useHistory } from 'react-router-dom';
import otherService from '../../../services/config/other.service';
import dayjs from 'dayjs';
import GenerateTicketModal from './generate_ticket_modal';
import { useSelector } from 'react-redux';
import globalHelper from '../../../helpers/functions/global.helper';
import moment from 'moment';
import ticketService from '../../../services/ticket/ticket.service';
import PageHeaderComponent from '../../../helpers/components/page_header.component';
import serviceHelper from '../../../helpers/functions/service.helper';
import numberHelper from '../../../helpers/functions/number.helper';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const { RangePicker } = DatePicker;
const TicketLayout = ({ default_filter, }) => {
    const ticketRedux = useSelector(state => state?.ticket);
    const { innerWidth } = window;
    const history = useHistory();
    const [state, setState] = useState({
        loading : false,
        parks : [],
        purchase_types : [],
        payment_statuses : [],
        ticket_types : [],

        //open modal
        generate_modal : false,
        selected_purchase_type : null,

        //export
        generate_loading : false,

        complete_payment_status : null,
    });
    const [errors, setErrors] = useState();

    const [filter, setFilter] = useState({
        parks : default_filter?.parks,
        purchase_types : default_filter?.purchase_types,
        // start_date : default_filter?.start_date ? dayjs(default_filter?.start_date)?.format("YYYY-MM-DD") : dayjs()?.format("YYYY-MM-DD"),
        // end_date : default_filter?.end_date ? dayjs(default_filter?.end_date)?.format("YYYY-MM-DD") : dayjs()?.format("YYYY-MM-DD"),
        start_date : default_filter?.start_date,
        end_date : default_filter?.end_date,
        search : default_filter?.search,
        payment_statuses : null,
        ticket_types : default_filter?.ticket_types,
        page : default_filter?.page ?? 1,
        // visit_date : default_filter?.visit_date,
        visit_start_date : default_filter?.visit_start_date,
        visit_end_date : default_filter?.visit_end_date,
    });

    const [timer, setTimer] = useState(null);

    const fetchFilterItems = async () => {
        setState(state => ({...state, loading : true}));
        const parkResponse = await parkService.getParkByAuth();
        const purchaseTypeResponse = await otherService.getPurchaseTypes();
        const paymentStatusResponse = await otherService.getPaymentStatus();
        const ticketCategoryResponse = await ticketService?.getTicketType();

        setState(state => ({
            ...state, 
            loading : false, 
            parks : parkResponse?.parks, 
            purchase_types : purchaseTypeResponse?.purchase_types, 
            payment_statuses : paymentStatusResponse?.payment_statuses,
            ticket_types : ticketCategoryResponse?.ticket_types,
        }))
    }

    const onCheckChange = (key, checkedValues) => {
        setFilter(filter => ({...filter, [key] : checkedValues, }));
        changeFilter(key, checkedValues);
    }

    const changeDate = (key1, key2, date) => {
        if(date){
            const start_date = dayjs(date[0])?.format("YYYY-MM-DD");
            const end_date = dayjs(date[1])?.format("YYYY-MM-DD");

            setFilter(filter => ({...filter, [key1] : start_date, [key2] : end_date}));
            changeFilter(key1, start_date);
            changeFilter(key2, end_date);
        }else{
            setFilter(filter => ({...filter, [key1] : null, [key2] : null}));
            changeFilter(key1, null);
            changeFilter(key2, null);
        }
    }

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            setFilter(filter => ({ ...filter, search: input, }));
            changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    const exportTransactions = async () => {
        setState(state => ({...state, generate_loading : true, }));
        //refetch again with user detail true
        const { parks, purchase_types, start_date, end_date, search, payment_statuses, visit_start_date, visit_end_date, } = filter;
        const paymentStatusResponse = await otherService.getPaymentStatus();
        //set filter on only completed payment status
        const complete_payment_status = paymentStatusResponse?.payment_statuses?.filter(x => x.is_payment_complete);
        const complete_payment_status_ids = complete_payment_status?.map(x => x.payment_status_id);

        const ticketResponse = await ticketService.getTicketListing({
            purchase_type_ids : purchase_types?.join(","), // 2 for offline
            park_ids : parks?.join(","),
            // payment_status_ids : payment_statuses?.join(","),
            payment_status_ids : complete_payment_status_ids,
            search : search,
            // payment date
            start_date, end_date,
            is_fetch_full_users : true,
            is_all : true,
            // visit date
            visit_start_date, visit_end_date,

            // order by date
            // order : 'ticket_id',
            // order_position : 'DESC',
        }).catch(err => setState(state => ({...state, generate_loading : false,})));

        const tickets = ticketResponse?.tickets?.rows;

        const transaction_data = tickets?.map((ticket, index) => {
            const { name, email, running_number, payment_date, created_at, payment_amount, tax_amount, actual_price, ticket_type_name, unique_no, visit_date, currency_code, currency_symbol, purchase_type_name, promotion_code_name, salt, } = ticket ?? {};
            const count = (index + 1);
            return {
                "No" : count,
                "Buyer Name" : name,
                "Email Address" : email,
                "Invoice" :  running_number,
                "Paid Date" : moment(payment_date)?.isValid() ? moment(payment_date)?.format("DD MMM YYYY HH:mm") : "",
                "Order Date" : moment(created_at)?.format("DD MMM YYYY HH:mm"),
                "Currency" : currency_code,
                "Gross Price" : actual_price,
                "Net Price" : payment_amount,
                "Tax Amount" : tax_amount,
                "Promotion Code" : promotion_code_name,
                "Transaction No" : `${unique_no}${salt ?? ''}`,
                "Visit Date" : moment(visit_date)?.isValid() ? moment(visit_date)?.format("DD MMM YYYY") : "Open Date",
                "Ticket Variant" : ticket_type_name,
                "Purchase Type" : purchase_type_name,
            }
        });
        globalHelper.exportToExcel({
            data : transaction_data,
            // additional_data : [[[`Maximum Rows: ${5000}`]]],
            name : `transactions_${moment()?.format("YYYYMMDD")}`,
        });

        setState(state => ({...state, generate_loading : false, }));
    }

    const exportTickets = async () => {
        setState(state => ({...state, generate_loading : true, }));
        //refetch again with user detail true
        const { parks, purchase_types, start_date, end_date, search, payment_statuses, visit_start_date, visit_end_date, } = filter;
        const paymentStatusResponse = await otherService.getPaymentStatus();
        //set filter on only completed payment status
        const complete_payment_status = paymentStatusResponse?.payment_statuses?.filter(x => x.is_payment_complete);
        const complete_payment_status_ids = complete_payment_status?.map(x => x.payment_status_id);

        const ticketUserResponse = await ticketService?.getTicketUserListing({
            purchase_type_ids : purchase_types?.join(","), // 2 for offline
            park_ids : parks?.join(","),
            // payment_status_ids : payment_statuses?.join(","),
            payment_status_ids : complete_payment_status_ids,
            search : search,
            start_date, end_date,
            is_fetch_full_users : true,
            // visit_date,
            visit_start_date,
            visit_end_date,
            is_all : true,
        }).catch(err => setState(state => ({...state, generate_loading : false,})));
        
        const { ticket_users } = ticketUserResponse ?? {};
        const { rows : ticket_user_list, count } = ticket_users;
        if(ticket_user_list?.length > 0){
            let ticket_user_data = [];
            let index = 0;
            ticket_user_list?.map((ticket_user) => {
                index = index + 1;
                const { ticket_name : buyer_name, ticket_user_no, running_number, payment_date, order_date, currency_code, price, ticket_price_amount, tax_amount : ticket_tax_amount, promotion_code, open_date, time_log, ticket_type_name, purchase_type_name, ticket_user_type_string, } = ticket_user;
                const age = ticket_user?.ticket_user_type?.min_age ? (
                    `(Age: ${ticket_user?.ticket_user_type?.max_age ? 
                            `${ticket_user?.ticket_user_type?.min_age} - ${ticket_user?.ticket_user_type?.max_age}` :
                            `${ticket_user?.ticket_user_type?.min_age} - 100+`
                    })`
                ) : "";

                const ticket_user_obj = {
                    "No" : index,
                    "Buyer Name" : buyer_name,
                    "Invoice" :  running_number,
                    "Paid Date" : moment(payment_date)?.isValid() ? moment(payment_date)?.format("DD MMM YYYY HH:mm") : "",
                    "Order Date" : moment(order_date)?.format("DD MMM YYYY HH:mm"),
                    "Currency" : currency_code,
                    "Gross Price" : price,
                    "Net Price" : ticket_price_amount,
                    "Tax Amount" : ticket_tax_amount,
                    "Promotion Code" : promotion_code,
                    // "Promotion Amount" : ticket_promotion_amount?.toFixed(2),
                    "Ticket Type" : ticket_user_type_string,
                    "Section" : ticket_user_type_string,
                    "Ticket No" : ticket_user_no,
                    "Visit Date" : moment(open_date)?.isValid() ? moment(open_date)?.format("DD MMM YYYY") : "Open Date",
                    "Check In Date Time" : (time_log && moment(time_log)?.isValid()) ? moment(time_log)?.format("DD MMM YYYY HH:mm"): "",
                    "Ticket Variant" : ticket_type_name,
                    "Purchase Type" : purchase_type_name,
                }

                ticket_user_data?.push(ticket_user_obj);
            })

            globalHelper.exportToExcel({
                data : ticket_user_data,
                name : `tickets_${moment()?.format("YYYYMMDD")}`,
            });
        }

        // const ticketResponse = await ticketService.getTicket({
        //     purchase_type_ids : purchase_types?.join(","), // 2 for offline
        //     park_ids : parks?.join(","),
        //     // payment_status_ids : payment_statuses?.join(","),
        //     payment_status_ids : complete_payment_status_ids,
        //     search : search,
        //     start_date, end_date,
        //     is_fetch_full_users : true,
        //     // visit_date,
        //     visit_start_date,
        //     visit_end_date,
        // }).catch(err => setState(state => ({...state, generate_loading : false,})));

        // const { tickets } = ticketResponse ?? {};
        // if(tickets?.length > 0){
        //     let ticket_user_data = [];
        //     let index = 0;
        //     for(const ticket of tickets){
        //         const { name, running_number, payment_date, created_at, payment_amount, ticket_type, unique_no, visit_date, purchase_type, ticket_user_details, promotion_code, park, } = ticket ?? {};
        //         ticket_user_details?.map((ticket_user, ) => {
        //             index = index + 1;
        //             const { ticket_user_no, price, actual_price : ticket_actual_price, tax_amount : ticket_tax_amount, ticket_user_type, entrance_transaction_records, } = ticket_user;
        //             const age = ticket_user?.ticket_user_type?.min_age ? (
        //                 `(Age: ${ticket_user?.ticket_user_type?.max_age ? 
        //                         `${ticket_user?.ticket_user_type?.min_age} - ${ticket_user?.ticket_user_type?.max_age}` :
        //                         `${ticket_user?.ticket_user_type?.min_age} - 100+`
        //                 })`
        //             ) : "";
    
        //             //get latest checkin time
        //             const latest_checked_in = entrance_transaction_records?.length > 0 ? entrance_transaction_records[(entrance_transaction_records?.length - 1)] : null;
        //             const ticket_promotion_amount = ((isNaN(parseFloat(ticket_actual_price)) ? 0 : parseFloat(ticket_actual_price)) - parseFloat(price));
        //             const ticket_user_type_string = `${ticket_user_type?.name} ${age}`;
        //             const ticket_price_amount = (parseFloat(price) + (isNaN(parseFloat(ticket_tax_amount)) ? 0 : parseFloat(ticket_tax_amount)));
        //             const ticket_user_obj = {
        //                 "No" : index,
        //                 "Buyer Name" : name,
        //                 "Invoice" :  running_number,
        //                 "Paid Date" : moment(payment_date)?.isValid() ? moment(payment_date)?.format("DD MMM YYYY HH:mm") : "",
        //                 "Order Date" : moment(created_at)?.format("DD MMM YYYY HH:mm"),
        //                 "Currency" : park?.currency?.code,
        //                 "Gross Price" : price,
        //                 "Net Price" : ticket_price_amount?.toFixed(2),
        //                 "Tax Amount" : ticket_tax_amount,
        //                 "Promotion Code" : promotion_code?.promo_code,
        //                 // "Promotion Amount" : ticket_promotion_amount?.toFixed(2),
        //                 "Ticket Type" : ticket_user_type_string,
        //                 "Section" : ticket_user_type_string,
        //                 "Ticket No" : ticket_user_no,
        //                 "Visit Date" : moment(visit_date)?.isValid() ? moment(visit_date)?.format("DD MMM YYYY") : "Open Date",
        //                 "Check In Date Time" : (latest_checked_in?.time_log && moment(latest_checked_in?.time_log)?.isValid()) ? moment(latest_checked_in?.time_log)?.format("DD MMM YYYY HH:mm"): "",
        //                 "Ticket Variant" : ticket_type?.name,
        //                 "Purchase Type" : purchase_type?.name,
        //             }
        //             ticket_user_data?.push(ticket_user_obj);
        //         })
        //     }

        //     globalHelper.exportToExcel({
        //         data : ticket_user_data,
        //         name : `tickets_${moment()?.format("YYYYMMDD")}`,
        //     });
        // }

        setState(state => ({...state, generate_loading : false, }));
    }

    const exportAddOns = async () => {
        setState(state => ({...state, generate_loading : true, }));
        //refetch again with user detail true
        const { parks, purchase_types, start_date, end_date, search, payment_statuses, visit_start_date, visit_end_date, } = filter;
        const paymentStatusResponse = await otherService.getPaymentStatus();
        //set filter on only completed payment status
        const complete_payment_status = paymentStatusResponse?.payment_statuses?.filter(x => x.is_payment_complete);
        const complete_payment_status_ids = complete_payment_status?.map(x => x.payment_status_id);

        const ticketAddOnListing = await ticketService?.getTicketAddOnListing({
            purchase_type_ids : purchase_types?.join(","), // 2 for offline
            park_ids : parks?.join(","),
            // payment_status_ids : payment_statuses?.join(","),
            payment_status_ids : complete_payment_status_ids,
            search : search,
            start_date, end_date,
            is_fetch_full_users : true,
            // visit_date,
            visit_start_date,
            visit_end_date,
            is_all : true,
        }).catch(err => setState(state => ({...state, generate_loading : false,})));

        const { ticket_add_ons, } = ticketAddOnListing;
        const { rows : ticket_add_on_listing, count, } = ticket_add_ons;
        if(ticket_add_on_listing?.length > 0){
            let ticket_add_on_data = [];
            let index = 0;

            ticket_add_on_listing?.map((ticket_add_on) => {
                index = index + 1;
                const { actual_price, add_on, created_at, currency_code, currency_name, email, name, park_id, payment_date, payment_status_id, payment_status_name, price, quantity, total_price, promotion_code, purchase_type, remarks, running_number, salt, ticket_add_on_id, ticket_type_id, transaction_salt_text, unique_no, visit_date, add_on_type, } = ticket_add_on;

                const ticket_add_on_obj = {
                    "No" : index,
                    "Buyer Name" : name,
                    "Invoice" :  running_number,
                    "Transaction No" : transaction_salt_text,
                    "Paid Date" : moment(payment_date)?.isValid() ? moment(payment_date)?.format("DD MMM YYYY HH:mm") : "",
                    "Order Date" : moment(created_at)?.format("DD MMM YYYY HH:mm"),
                    "Currency" : currency_code,
                    "Gross Price" : actual_price,
                    "Net Price" : price,
                    "Quantity" : quantity,
                    "Total Price" : total_price,
                    "Promotion Code" : promotion_code,
                    "Add On" : add_on,
                    "Add On Type" : add_on_type,
                    "Visit Date" : moment(visit_date)?.isValid() ? moment(visit_date)?.format("DD MMM YYYY") : "Open Date",
                    "Purchase Type" : purchase_type,
                }

                ticket_add_on_data?.push(ticket_add_on_obj);
            })

            globalHelper.exportToExcel({
                data : ticket_add_on_data,
                name : `add_ons_${moment()?.format("YYYYMMDD")}`,
            });
        }

        setState(state => ({...state, generate_loading : false, }));
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        
        setFilter(filter => ({...filter, page : 1,}));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    useEffect(() => {
        fetchFilterItems();
    }, []);

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Tickets'} />
                </div>
                <div>
                    <Input
                    onChange={(e) => doneTyping(e.target.value)}
                    name="search"
                    defaultValue={filter?.search}
                    prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                    placeholder='Search Transaction Number'
                    style={{minWidth: '100%',}}
                    allowClear
                    {...(errors?.find(x => x?.type === 'search_length') && ({ status : 'warning', }))}
                    />
                    {
                        errors?.find(x => x?.type === 'search_length') && (
                            <>
                                <EntranceMotion>
                                    <div style={{ textAlign : 'start', }}>
                                        <span style={{ color : '#faad14' }}>{errors?.find(x => x?.type === 'search_length')?.message}</span>
                                    </div>
                                </EntranceMotion>
                            </>
                        )
                    }
                </div>
                <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                    <div>
                        <div className='filter-panel' style={{textAlign:'start'}}>
                            <Space direction={innerWidth > 768 ? 'horizontal' : 'vertical'}>
                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Parks' />
                                        </div>
                                        
                                        <Checkbox.Group
                                        disabled={ticketRedux?.list_loading}
                                        value={filter?.parks}
                                        onChange={(checkedValues) => onCheckChange('parks', checkedValues)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.parks}
                                            renderItem={(item, index) => {
                                                const { park_id, name, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Checkbox
                                                                value={park_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Checkbox.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Park {filter?.parks?.length > 0 ? `: ${filter?.parks?.length}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>

                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Purchase Type' />
                                        </div>
                                        
                                        <Checkbox.Group
                                        disabled={ticketRedux?.list_loading}
                                        value={filter?.ticket_types}
                                        onChange={(checkedValues) => onCheckChange('ticket_types', checkedValues)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.ticket_types}
                                            renderItem={(item, index) => {
                                                const { ticket_type_id, name, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Checkbox
                                                                value={ticket_type_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Checkbox.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Ticket Category {filter?.ticket_types?.length > 0 ? `: ${filter?.ticket_types?.length}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>

                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <Input prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}}/>} placeholder='Search Purchase Type' />
                                        </div>
                                        
                                        <Checkbox.Group
                                        disabled={ticketRedux?.list_loading}
                                        value={filter?.purchase_types}
                                        onChange={(checkedValues) => onCheckChange('purchase_types', checkedValues)}
                                        >
                                            <List
                                            style={{ width:280, }}
                                            dataSource={state?.purchase_types}
                                            renderItem={(item, index) => {
                                                const { purchase_type_id, name, } = item ?? {};
                                                return (
                                                    <List.Item style={{width:'100%'}} key={index}>
                                                        <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                            <div>
                                                                <span>{name}</span>
                                                            </div>
                                                            <div style={{marginLeft:'auto'}}>
                                                                <Checkbox
                                                                value={purchase_type_id}
                                                                />
                                                            </div>
                                                        </div>
                                                    </List.Item>
                                                )
                                            }}
                                            />
                                        </Checkbox.Group>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Purchase Types {filter?.purchase_types?.length > 0 ? `: ${filter?.purchase_types?.length}` : ''}</span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>

                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            <RangePicker
                                            {...(filter?.start_date && filter?.end_date) && ({ defaultValue : [dayjs(filter?.start_date), dayjs(filter?.end_date)] })}
                                            disabled={ticketRedux?.list_loading}
                                            onChange={(e) => changeDate('start_date', 'end_date', e)} format={"YYYY-MM-DD"} 
                                            />
                                        </div>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Payment Date
                                                    <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                        {filter?.start_date && filter?.end_date && `: ${filter?.start_date}-${filter?.end_date}`}
                                                    </span>
                                                </span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                    </div>
                                </FilterBullet>

                                <FilterBullet
                                popover={
                                    <>
                                        <div>
                                            {/* <DatePicker
                                            {...filter?.visit_date && ({ defaultValue : dayjs(filter?.visit_date) })}
                                            // defaultValue={dayjs(filter?.visit_date) ?? null}
                                            disabled={ticketRedux?.list_loading}
                                            onChange={(e, j) => onCheckChange('visit_date', j)} 
                                            format={"YYYY-MM-DD"} 
                                            /> */}
                                            <RangePicker
                                            {...(filter?.visit_start_date && filter?.visit_end_date) && ({ defaultValue : [dayjs(filter?.visit_start_date), dayjs(filter?.visit_end_date)] })}
                                            disabled={ticketRedux?.list_loading}
                                            onChange={(e) => changeDate('visit_start_date', 'visit_end_date', e)}
                                            format={"YYYY-MM-DD"} 
                                            />
                                        </div>
                                    </>
                                }
                                >
                                    <div>
                                        <Space>
                                            <div>
                                                <span>Visit Date
                                                    <span style={{fontSize:12, fontWeight:500, color:'var(--secondary-text-color)'}}>
                                                        {filter?.visit_start_date && filter?.visit_end_date && `: ${filter?.visit_start_date}-${filter?.visit_end_date}`}
                                                    </span>
                                                </span>
                                            </div>
                                            <div>
                                                <CaretDownFilled style={{color:'#a0aec0'}} />
                                            </div>
                                        </Space>
                                        
                                    </div>
                                </FilterBullet>
                            </Space>
                        </div>
                    </div>

                    <div style={{marginLeft:'auto'}}>
                        <Space>
                            <Tooltip title="Please filter by payment date or visit date">
                                <Dropdown
                                placement='bottomRight'
                                overlayStyle={{ borderRadius: 12, minWidth: 200 }}
                                menu={{
                                    items: [{
                                        key: 1,
                                        disabled : !((filter?.start_date && filter?.end_date) || (filter?.visit_start_date && filter?.visit_end_date) ),
                                        label: (
                                            <>
                                                <div>
                                                    <div>
                                                        <span>
                                                            Export Transactions
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>
                                                            Maximum exports : 50,000 transactions
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                        onClick : async () => {
                                            exportTransactions();
                                
                                        }
                                    },{
                                        key: 2,
                                        disabled : !((filter?.start_date && filter?.end_date) || (filter?.visit_start_date && filter?.visit_end_date) ),
                                        label: (
                                            <>
                                                <div>
                                                    <div>
                                                        <span>
                                                            Export Tickets
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>
                                                            Maximum exports : 50,000 tickets
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                        onClick : async () => {
                                            exportTickets();
                                        }
                                    },{
                                        key: 3,
                                        disabled : !((filter?.start_date && filter?.end_date) || (filter?.visit_start_date && filter?.visit_end_date) ),
                                        label: (
                                            <>
                                                <div>
                                                    <div>
                                                        <span>
                                                            Export Add Ons
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>
                                                            Maximum exports : 50,000 add ons
                                                        </span>
                                                    </div>
                                                </div>
                                            </>
                                        ),
                                        onClick : async () => {
                                            exportAddOns();
                                        }
                                    }]
                                }}
                                >
                                    <Button loading={state?.generate_loading} icon={<DownOutlined />}>
                                        Export
                                    </Button>
                                </Dropdown>
                            </Tooltip>
                            {/* <Button
                            onClick={exportTickets}
                            >
                                Export Tickets
                            </Button> */}
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, generate_modal : true,}))}
                            >
                                Generate Tickets
                            </Button>
                        </Space>
                    </div>
                </div>
                
                <div style={{marginTop:12,}}>
                    <TicketList filter={filter} default_filter={default_filter} setErrors={setErrors} /> {/* default filter is for paginate to replace state of url */}
                </div>
            </div>

            <Modal
            title="Generate Tickets"
            open={state?.generate_modal}
            onCancel={() => setState(state => ({...state, generate_modal : false,}))}
            onOk={() => {
                const purchase_type_code = state?.selected_purchase_type?.code;
                history.push(`/${purchase_type_code}/generate`);
            }}
            okText={'Next'}
            okButtonProps={{ disabled : !state?.selected_purchase_type}}
            >
                <>
                    <div style={{padding:'24px 0'}}>
                        {/* pass set state function */}
                        <GenerateTicketModal parentState={state} setParentState={setState} /> 
                    </div>
                </>
            </Modal>
        </>
    );
}

export default TicketLayout;