import React, { useState } from 'react';
import ContainerComponent from '../../../helpers/components/container.component';
import ConfigAddOnLayout from './components/config_add_on_layout';
import { useLocation } from 'react-router-dom';

const ConfigAddOnIndex = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [filter, setFilter] = useState({
        add_on_types : queryParams.get("add_on_types"),
        search : queryParams?.get("search"),
    })

    return (
        <>
            <ContainerComponent span={22} top={20} bottom={20}>
                <ConfigAddOnLayout default_filter={filter} />
            </ContainerComponent>
        </>
    );
}

export default ConfigAddOnIndex;