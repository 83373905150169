import { combineReducers } from 'redux';

//reducers
import ticketReducer from './ticket.reducer';
import authReducer from './auth.reducer';
import accountReducer from './account.reducer';
import configReducer from './config.reducer';
import dashboardReducer from './dashboard.reducer';

const allReducers = combineReducers({
    ticket : ticketReducer,
    auth : authReducer,
    account : accountReducer,
    config : configReducer,
    dashboard : dashboardReducer,
});

const rootReducer = (state, action) => {
    return allReducers(state, action);
}

export default rootReducer;