import React, { useEffect, useState } from 'react';
import ConfigAddOnList from './config_add_on_list';
import { Button, Checkbox, Form, Input, List, Modal, Space, notification } from 'antd';
import PageHeaderComponent from '../../../../helpers/components/page_header.component';
import { CaretDownFilled, SearchOutlined } from '@ant-design/icons';
import globalHelper from '../../../../helpers/functions/global.helper';
import AddAddOnModal from './add_add_on_modal';
import addOnService from '../../../../services/config/add_on.service';
import EditAddOnModal from './edit_add_on_modal';
import FilterBullet from '../../../../helpers/components/filter_bullet';
import serviceHelper from '../../../../helpers/functions/service.helper';

const ConfigAddOnLayout = ({ default_filter, }) => {
    const [save_form] = Form.useForm();
    const [update_form] = Form.useForm();
    const [state, setState] = useState({
        create_modal : false,
        edit_modal : null,

        save_loading : false,
        update_loading : false,

        modal : 0,
        refresh : 0,
    })

    const [timer, setTimer] = useState();

    const [selections, setSelect] = useState({
        add_on_types : [],
    })

    const [filter, setFilter] = useState({
        add_on_types : default_filter?.add_on_types ?? [],
        search : default_filter?.search,
    });

    const saveAddOn = async () => {
        setState(state => ({...state, save_loading : true, }));
        const { image, name, description, add_on_type_id, } = save_form?.getFieldsValue();
        const saveAddOnResponse = await addOnService?.saveAddOn({
            image : image?.file,
            name, description, add_on_type_id,
        })?.catch(err => setState(state => ({...state, save_loading : false, })));

        notification?.success({
            message : 'New add on added',
        })

        setState(state => ({...state, save_loading : false, modal : (state?.modal + 1), refresh : (state?.refresh + 1), create_modal : false, }));
    }

    const updateAddOn = async () => {
        setState(state => ({...state, update_loading : true, }));
        const add_on_id = state?.edit_modal;
        const { image, name, description, add_on_type_id, } = update_form?.getFieldsValue();
       
        const updateAddOnResponse = await addOnService?.updateAddOn(add_on_id, {
            image : image?.file,
            name, description, add_on_type_id,
        }).catch(err => setState(state => ({...state, update_loading : false,})))
        
        notification?.success({
            message : 'Add on updated',
        })

        setState(state => ({...state, update_loading : false, modal : (state?.modal + 1), refresh : (state?.refresh + 1), edit_modal : null, }));
    }

    const deleteAddOn = async (add_on_id) => {
        const deleteAddOnResponse = await addOnService?.deleteAddOn(add_on_id);

        notification?.success({
            message : 'Add on Deleted',
        })

        setState(state => ({...state, refresh : (state?.refresh + 1)}));
    }

    const openEditModal = (add_on_id) => {
        setState(state => ({...state, edit_modal : add_on_id, }));
    }

    const fetchAddOnTypes = async () => {
        const addOnTypeResponse = await addOnService?.fetchAddOnType();
        const { add_on_types } = addOnTypeResponse;

        return add_on_types;
    }

    const onCheckChange = (key, checkedValues) => {
        // setFilter(filter => ({...filter, [key] : checkedValues, }));
        changeFilter(key, checkedValues);
    }

    const doneTyping = (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            changeFilter('search', input);
        }, 300);
        setTimer(newTimer);
    }

    // for changing filter
    const changeFilter = (key, value) => {
        let generate_filter = default_filter;
        generate_filter[key] = value;
        
        setFilter(filter => ({...filter, page : 1, [key] : value, }));
        const queryString = serviceHelper?.queryString(generate_filter);
        window.history.replaceState({}, '', `${window.location.pathname}?${queryString}`);
    }

    const initialize = async () => {
        const add_on_types = await fetchAddOnTypes();

        setSelect(selections => ({...selections, add_on_types : add_on_types, }));
    }

    useEffect(() => {
        initialize();
    }, [])

    return (
        <>
            <div className='mobile-padding-12'>
                <div style={{marginBottom: 24, textAlign:'start'}}>
                    <PageHeaderComponent title={'Add Ons'} />
                </div>
                <div className='filter-panel'>
                    <div>
                        <Input
                        defaultValue={default_filter?.search}
                        onChange={(e) => doneTyping(e.target.value)}
                        name="search"
                        prefix={<SearchOutlined style={{color :'var(--secondary-text-color)'}} />}
                        placeholder='Search Add Ons'
                        style={{minWidth: '100%',}}
                        allowClear
                        />
                    </div>

                    <div style={{display:'flex', alignItems:'center', marginTop:12, gap:8,}}>
                        <div>
                            <div className='filter-panel' style={{textAlign:'start'}}>
                                <Space direction={globalHelper?.isMobileScreen() ? 'vertical' : 'horizontal'}>
                                    <FilterBullet
                                    popover={
                                        <>
                                            <Checkbox.Group
                                            value={filter?.add_on_types}
                                            onChange={(checkedValues) => onCheckChange('add_on_types', checkedValues)}
                                            >
                                                <List
                                                style={{ width:280, }}
                                                dataSource={selections?.add_on_types}
                                                renderItem={(item, index) => {
                                                    const { add_on_type_id, name, } = item ?? {};
                                                    return (
                                                        <List.Item style={{width:'100%'}} key={index}>
                                                            <div style={{display:'flex', alignItems:'center', width:'100%'}}>
                                                                <div>
                                                                    <span>{name}</span>
                                                                </div>
                                                                <div style={{marginLeft:'auto'}}>
                                                                    <Checkbox
                                                                    value={add_on_type_id}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    )
                                                }}
                                                />
                                            </Checkbox.Group>
                                        </>
                                    }
                                    >
                                        <div>
                                            <Space>
                                                <div>
                                                    <span>Add On Type {filter?.add_on_types?.length > 0 ? `: ${filter?.add_on_types?.length}` : ''}</span>
                                                </div>
                                                <div>
                                                    <CaretDownFilled style={{color:'#a0aec0'}} />
                                                </div>
                                            </Space>
                                            
                                        </div>
                                    </FilterBullet>
                                </Space>
                            </div>
                        </div>

                        <div style={{marginLeft:'auto'}}>
                            <Button
                            type='primary'
                            onClick={() => setState(state => ({...state, create_modal : true,}))}
                            >
                                New Add On
                            </Button>
                        </div>
                    </div>
                </div>

                <div style={{marginTop:24, }}>
                    <ConfigAddOnList refresh={state?.refresh} filter={filter} openEditModal={openEditModal} deleteAddOn={deleteAddOn} />
                </div>
            </div>

            <Modal
            title="New Add On"
            open={state?.create_modal}
            onCancel={() => {
                setState(state => ({...state, create_modal : false, modal : (state?.modal + 1)}));
                // save_form?.resetFields();
            }}
            onOk={() => save_form.submit()}
            width={800}
            // style={{ top : 20, }}
            confirmLoading={state?.save_loading}
            >
                <Form
                form={save_form}
                onFinish={saveAddOn}
                layout='vertical'
                >
                    <AddAddOnModal form={save_form} />
                </Form>
            </Modal>

            <Modal
            title="Edit Add On"
            open={state?.edit_modal}
            onCancel={() => {
                setState(state => ({...state, edit_modal : null, modal : (state?.modal + 1)}));
                // save_form?.resetFields();
            }}
            onOk={() => update_form.submit()}
            width={800}
            // style={{ top : 20, }}
            confirmLoading={state?.update_loading}
            >
                <Form
                form={update_form}
                onFinish={updateAddOn}
                layout='vertical'
                >
                    <EditAddOnModal form={update_form} add_on_id={state?.edit_modal} modal={state?.modal} />
                </Form>
            </Modal>
        </>
    );
}

export default ConfigAddOnLayout;