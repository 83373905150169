import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function getTicket(query){
    const queryString = serviceHelper.queryString(query);
    const ticketResponse = await axios.get(`ticket/get?${queryString}`, { withCredentials : true });
    return ticketResponse?.data;
}

async function getTicketListing(query){
    const queryString = serviceHelper.queryString(query);
    const ticketListingResponse = await axios.get(`ticket/listing?${queryString}`, { withCredentials : true });
    return ticketListingResponse?.data;
}

async function getTicketUser(query){
    const queryString = serviceHelper.queryString(query);
    const ticketUserResponse = await axios.get(`ticket/user/get?${queryString}`, { withCredentials : true });
    return ticketUserResponse?.data;
}

async function getTicketUserListing(query){
    const queryString = serviceHelper?.queryString(query);
    const ticketUserListingResponse = await axios.get(`ticket/user/listing?${queryString}`, { withCredentials : true,  });
    return ticketUserListingResponse?.data;
}

async function getTicketAddOnListing(query){
    const queryString = serviceHelper?.queryString(query);
    const ticketAddOnListingResponse = await axios?.get(`ticket/add_on/listing?${queryString}`, { withCredentials : true, });
    return ticketAddOnListingResponse?.data;
}

async function generateTicket(params){
    const purchaseResponse = await axios.post(`ticket/generate/ticket`, params, { withCredentials: true, timeout : 300000, }); // this special case, set 5 minutes for timeout
    return purchaseResponse?.data;
}

async function updateTicket(ticket_id, params){
    const updateTicketResponse = await axios.post(`ticket/tk/${ticket_id}/update`, params, { withCredentials : true, });
    return updateTicketResponse?.data;
}

async function sendEmail(ticket_id){
    const sendEmailResponse = await axios.post(`ticket/tk/${ticket_id}/email`, { withCredentials : true, });
    return sendEmailResponse?.data;
}

async function checkIsCheckedIn(ticket_id){
    const checkInResponse = await axios.get(`ticket/tk/${ticket_id}/check_in`, { withCredentials : true, });;
    return checkInResponse?.data;
}

async function getTicketType(query){
    const queryString = serviceHelper.queryString(query);
    const ticketTypeResponse = await axios.get(`ticket/ticket_type/get?${queryString}`, { withCredentials : true, });
    return ticketTypeResponse?.data;
}

async function getIntegrationLog(query){
    const queryString = serviceHelper.queryString(query);
    const integrationLogResponse = await axios.get(`ticket/integration/log?${queryString}`, { withCredentials : true, });
    return integrationLogResponse?.data;
}

async function calculateOpenPricing(params){
    const calculateResponse = await axios.post(`ticket/price/open/calculate`, params, { withCredentials : true, });
    return calculateResponse?.data;
}

async function getTicketField(ticket_user_no, query){
    const queryString = serviceHelper.queryString(query);
    const ticketFieldResponse = await axios.get(`ticket/user/${ticket_user_no}/field?${queryString}`, { withCredentials : true });
    return ticketFieldResponse?.data;
}

async function updateTicketUser(ticket_user_no, params = {}, fields){
    const { name, age, gender, avatar, visit_date } = params;
    const form_data = new FormData();
    name && form_data.append('name', name);
    age && form_data.append('age', age);
    gender && form_data.append('gender', gender);
    visit_date && form_data.append('visit_date', visit_date);
    avatar && form_data.append('avatar', avatar);

    // dynamic field
    for(const field of fields){
        const { key } = field;
        const dynamic_form_data = params[key];
        dynamic_form_data && form_data.append(key, dynamic_form_data);
    }

    const ticketUserResponse = await axios.post(`ticket/user/update/${ticket_user_no}`, form_data,
    { withCredentials : true, headers : { "Content-Type": "multipart/form-data" }});

    return ticketUserResponse?.data;
}

export default {
    getTicket,
    updateTicketUser,
    getTicketListing,
    generateTicket,
    getTicketUser,
    getTicketUserListing,
    getTicketAddOnListing,
    updateTicket,
    sendEmail,
    checkIsCheckedIn,
    getTicketType,
    getIntegrationLog,
    calculateOpenPricing,
    getTicketField,
}