const initialState = {
    // promo code
    selected_park_id : null,
}

const dashboardReducer = (state = initialState, action) => {
    switch(action.type){
        case 'SELECT_PARK_ID':
            return {...state, selected_park_id : action?.selected_park_id }
        default :
            return state;
    }
}

export default dashboardReducer;