import { Card, Skeleton, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import OverallChart from '../../operation/report/visitor/overall_chart';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import entranceService from '../../../services/operation/entrance.service';

const VisitorCount = ({ dashboard, }) => {
    const dashboardRedux = useSelector(state => state?.dashboard);
    const [state, setState] = useState({
        loading : false,
        expected_visitors : 0,
        checked_in_visitors : 0,
    })
    
    const fetchVisitorCount = async () => {
        
        const park_id = dashboardRedux?.selected_park_id;

        const visitorReportResponse = await entranceService?.generateVisitorReports({
            park_id,
            visit_date : dayjs()?.format("YYYY-MM-DD"),
        });

        const { checked_in_visitors, expected_visitors, } = visitorReportResponse;
        setState(state => ({...state, loading : false, checked_in_visitors, expected_visitors, }));
    }

    useEffect(() => {
        setState(state => ({...state, loading : true, }));
        if(dashboardRedux?.selected_park_id){
            fetchVisitorCount();
        }
    }, [dashboardRedux])

    return (
        <>
            <Card
            title={(
                <>
                    <div style={{ textAlign : 'start', }}>
                        <Space>
                            <span>{dashboard?.name}</span>
                            <span style={{ fontSize : 12, color : 'var(--secondary-text-color)', }}>{dayjs()?.format("YYYY-MM-DD")}</span>
                        </Space>
                    </div>
                </>
            )}
            >
                {
                    state?.loading ? (
                        <>
                            <Loading />
                        </>
                    )
                    :
                    (
                        <>
                            <Space direction='vertical' style={{ width : '100%', }}>
                                <div>
                                    <OverallChart checked_in_visitors={state?.checked_in_visitors} expected_visitors={state?.expected_visitors} />
                                </div>
                            </Space>
                        </>
                    )
                }
            </Card>
        </>
    );
}

const Loading = () => {
    return (
        <>
            <Skeleton avatar={{ size : 100, }} title={null} />
        </>
    );
}

export default VisitorCount;